const VISIBLE_CLASS = 'vjs-visible';

const useWatermark = (controller, player) => {
  const playerElement = $(player.el());
  const watermark = playerElement.siblings('.vjs-watermark');
  if (watermark.length === 0) {
    return;
  }

  watermark.appendTo(playerElement)

  let timer;

  const delay = (callback) => {
    const delay = Math.round(3 + 5 * Math.random()) * 1000;
    timer = setTimeout(() => {
      callback();
    }, delay);
  }

  const hideWatermark = () => {
    watermark.removeClass(VISIBLE_CLASS);
    delay(showWatermark);
  }

  const showWatermark = () => {
    if (!watermark.hasClass(VISIBLE_CLASS)) {
      watermark.addClass(VISIBLE_CLASS);

      const top = Math.round((playerElement.innerHeight() - watermark.height()) * Math.random())
      const left = Math.round((playerElement.innerWidth() - watermark.width()) * Math.random())
      watermark.css('top', top);
      watermark.css('left', left);
    }

    delay(hideWatermark);
  }

  const disableWatermark = () => {
    timer && clearTimeout(timer)
  }

  const disconnect = controller.disconnect
  controller.disconnect = () => {
    disableWatermark();

    player.off('play', showWatermark);
    player.off('pause', disableWatermark);

    disconnect();
  }

  player.on('play', showWatermark);
  player.on('pause', disableWatermark);
};

export default useWatermark;
