const useGoogleAnalytics = (controller, player, title) => {
  const disconnect = controller.disconnect

  let isLoaded = false;
  let seekStart = 0;
  let seekEnd = 0;
  let seeking = false;
  const percentsPlayedInterval = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90];
  const percentsAlreadyTracked = [];

  const getCurrentTime = () => Math.round(player.currentTime());

  const sendEvent = (label, value) => {
    let payload = {
      event_category: 'Videos',
      event_label: label,
      non_interaction: true
    };

    if (value) {
      payload['event_value'] = value;
    }

    console.log('[Video event] %s %s %s', title, label, value);
    window['gtag'] && gtag('event', title, payload);
  }

  const loaded = () => {
    isLoaded = true;
    sendEvent('loadedmetadata');
  }

  const timeupdate = () => {
    if (!isLoaded) {
      return;
    }

    const currentTime = getCurrentTime();
    const duration = Math.round(player.duration());
    const percentPlayed = Math.round(currentTime / duration * 100);

    percentsPlayedInterval.forEach((percent) => {
      if (percentPlayed >= percent && percentsAlreadyTracked.indexOf(percent) === -1) {
        if (percent === 0 && percentPlayed === 0) {
          sendEvent('start');
        } else if (percentPlayed > 0) {
          sendEvent('percent played', percent);
        }

        if (percentPlayed >= 0) {
          percentsAlreadyTracked.push(percent);
        }
      }
    });

    let seekStart = seekEnd;
    seekEnd = currentTime;
    if (Math.abs(seekStart - seekEnd) > 1) {
      seeking = true
      sendEvent('seek start', seekStart)
      sendEvent('seek end', seekEnd)
    }
  }

  const end = () => {
    sendEvent('end');
  }

  const play = () => {
    seeking = false;
    sendEvent('play', getCurrentTime());
  }

  const pause = () => {
    const currentTime = getCurrentTime();
    const duration = Math.round(player.duration());

    if (currentTime !== duration && !seeking) {
      sendEvent('pause', currentTime);
    }
  }

  const volumeChange = () => {
    const volume = player.muted() ? 0 : player.volume();

    sendEvent('volume change', volume);
  }

  const resize = () => {
    if (!isLoaded) {
      return;
    }

    sendEvent('resize', `${player.width()} x ${player.height()}`);
  }

  const error = () => {
    sendEvent('error', getCurrentTime());
  }

  const fullscreenChange = (event) => {
    const currentTime = getCurrentTime();
    if (player.isFullscreen()) {
      sendEvent('enter fullscreen', currentTime);
    } else {
      sendEvent('exit fullscreen', currentTime);
    }
  }

  controller.disconnect = () => {
    player.off("loadedmetadata", loaded);
    player.off("timeupdate", timeupdate);
    player.off("ended", end);
    player.off("play", play);
    player.off("pause", pause);
    player.off("volumechange", volumeChange);
    player.off("resize", resize);
    player.off("error", error);
    player.off("fullscreenchange", fullscreenChange);

    disconnect();
  }

  player.on("loadedmetadata", loaded);
  player.on("timeupdate", timeupdate);
  player.on("ended", end);
  player.on("play", play);
  player.on("pause", pause);
  player.on("volumechange", volumeChange);
  player.on("resize", resize);
  player.on("error", error);
  player.on("fullscreenchange", fullscreenChange);
};

export default useGoogleAnalytics;
