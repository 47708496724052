import ApplicationController from '../application-controller';

export default class extends ApplicationController {
  connect() {
  }

  buy(event) {
    event.preventDefault();
    this.dispatch('buy')
  }

  share(event) {
    event.preventDefault();
    this.dispatch('share')
  }

  save(event) {
    event.preventDefault();
    this.dispatch('save')
  }
}

