import ApplicationController from '../application-controller';
import {get, destroy} from '@rails/request.js'

export default class extends ApplicationController {
  static targets = ['form', 'messages', 'message']

  messageTargetConnected(message) {
    const {scrollHeight, clientHeight} = this.messagesTarget;
    const { offsetTop, clientHeight: messageHeight} = message;

    console.log('offsetTop %s',offsetTop)
    if (scrollHeight >= clientHeight) {
      this.messagesTarget.scrollTop = offsetTop - messageHeight;
    }
  }

  handleSubmitMessage(event) {
    this.formTarget.reset();
  }

  handleDeleteMessage(event) {
    event.preventDefault();
    destroy(event.currentTarget.href, {responseKind: 'turbo-stream'});
  }

  loadMore(event) {
    event.preventDefault();
    get(event.currentTarget.href, {responseKind: 'turbo-stream'});
  }
}

