import videojs from 'video.js';
import 'videojs-contrib-quality-levels';
import qualitySelector from '@silvermine/videojs-quality-selector'
import hlsQualitySelector from "videojs-hls-quality-selector";
import 'videojs-youtube';
import ruLocale from 'video.js/dist/lang/ru.json';
import 'videojs-errors';
import ruErrorsLocale from 'videojs-errors/dist/lang/ru.json';

videojs.addLanguage('ru', ruLocale);
videojs.addLanguage('ru', ruErrorsLocale);

qualitySelector(videojs);

const useVideo = (controller, element) => {
  const player = videojs(element, {
    html5: {
      vhs:{
        overrideNative: true,
      },
      nativeAudioTracks: false,
      nativeVideoTracks: false,
    }
  });

  player.controlBar.addChild('QualitySelector');

  player.hlsQualitySelector = hlsQualitySelector;
  // player.hlsQualitySelector();
  // player.controlBar.addChild('HlsQualitySelector');

  player.hlsQualitySelector({
    displayQualitySelector: true,
    // placementIndex: 1,
    displayCurrentQuality: false
  });

  return [player, videojs];
}

export default useVideo;
