import videojs from 'video.js';

const Button = videojs.getComponent('Button');
const componentName = 'theaterModeToggle';
const theaterClass = 'vjs-theater';

class TheaterModeToggle extends Button {

  constructor(player, options) {
    super(player, options);
    this.controlText('Toggle theater mode');
  }

  buildCSSClass() {
    return `vjs-theater-mode-control ${super.buildCSSClass()}`;
  }

  handleClick() {
    let theaterModeIsOn = this.player_.hasClass(theaterClass);

    if (theaterModeIsOn) {
      this.player_.removeClass(theaterClass);
    } else {
      this.player_.addClass(theaterClass);
    }

    this.player().trigger('theaterMode', !theaterModeIsOn);
  }
}

videojs.registerComponent('TheaterModeToggle', TheaterModeToggle);

const onPlayerReady = (player, options) => {
  let toggle = player.controlBar.addChild(componentName, options);
  player.controlBar.el().insertBefore(toggle.el(), player.controlBar.fullscreenToggle.el());
};

const theaterMode = function(options) {
  this.ready(() => {
    onPlayerReady(this, options);
  });

  this.on('fullscreenchange', (event) => {
    if (this.isFullscreen()) {
      this.controlBar.getChild(componentName).hide();
    } else {
      this.controlBar.getChild(componentName).show();
    }
  });
};

videojs.registerPlugin('theaterMode', theaterMode);

const useTheaterMode = (controller, player, options) => {
  if (controller.theaterModeInit) {
    return
  }

  controller.theaterModeInit = true
  player.theaterMode(options);

  player.on('theaterMode', (event, theaterMode) => {
    if (theaterMode) {
      options.theaterContainer.append(controller.element);
    } else {
      options.defaultContainer.prepend(controller.element);
    }
  })
}

export default useTheaterMode;

