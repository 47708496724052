import ApplicationController from '../application-controller';
import {Modal} from "bootstrap";

export default class extends ApplicationController {
  connect() {
    this.modal = new Modal(this.element);
  }

  show() {
    this.modal.show();
  }
}

