import { post } from '@rails/request.js'

import useFingerprint from '../../hooks/use-fingerprint'

const useLocalAnalytics = (controller, player, eventsSrc) => {
  const disconnect = controller.disconnect

  let isLoaded = false;
  const percentsPlayedInterval = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90];
  const percentsAlreadyTracked = [];

  const getCurrentTime = () => Math.round(player.currentTime());

  const sendEvent = () => {
    const fingerprint = useFingerprint();
    const payload = { event: { fingerprint, referer: document.referrer } };

    post(eventsSrc, {
      method: 'POST',
      body: JSON.stringify(payload),
      responseKind: 'json'
    });
  }

  const loaded = () => {
    isLoaded = true;
  }

  const timeupdate = () => {
    if (!isLoaded) {
      return;
    }

    const currentTime = getCurrentTime();
    const duration = Math.round(player.duration());
    const percentPlayed = Math.round(currentTime / duration * 100);

    percentsPlayedInterval.forEach((percent) => {
      if (percentPlayed >= percent && percentsAlreadyTracked.indexOf(percent) === -1) {
        if (percent === 0 && percentPlayed === 0) {
          sendEvent();
        } else if (percentPlayed > 0) {
          // sendEvent('percent played', percent);
        }

        if (percentPlayed >= 0) {
          percentsAlreadyTracked.push(percent);
        }
      }
    });
  }

  controller.disconnect = () => {
    player.off("loadedmetadata", loaded);
    player.off("timeupdate", timeupdate);

    disconnect();
  }

  player.on("loadedmetadata", loaded);
  player.on("timeupdate", timeupdate);
};

export default useLocalAnalytics;
