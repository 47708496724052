import { register } from "../../utils/controllers";

// TODO Used by video player. Should extract to video player font https://github.com/videojs/font
import 'bootstrap-icons/font/bootstrap-icons'
import {Alert, Dropdown} from 'bootstrap';

register(require.context('controllers/site', true, /-controller\.js$/));
register(require.context('controllers/ui', true, /modal-controller\.js$/));
register(require.context('controllers/ui', true, /video-controller\.js$/));
register(require.context('controllers/ui', true, /video-chapters-controller\.js$/));
register(require.context('controllers/ui', true, /chat-controller\.js$/));
register(require.context('controllers/ui', true, /slick-carousel-controller\.js$/));
register(require.context('controllers/ui', true, /clipboard-controller\.js$/));
