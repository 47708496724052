import {ApplicationController} from 'stimulus-use'

export default class extends ApplicationController {
  constructor(context) {
    super(context);

    const initialize = this.initialize.bind(this);
    const connect = this.connect.bind(this);
    const disconnect = this.disconnect.bind(this);

    Object.assign(this, {
      initialize: () => {
        if (this.element.dataset.status !== 'initialize') {
          this.element.dataset.status = 'initialized';

          console.log('[%s] Initialized', this.scope.identifier);

          initialize()
        }
      },
      connect: () => {
        if (this.element.dataset.status !== 'connected') {
          this.element.dataset.status = 'connected';

          console.log('[%s] Connected', this.scope.identifier);

          connect();
        }
      },
      disconnect: () => {
        if (this.element.dataset.status !== 'disconnected') {
          this.element.dataset.status = 'disconnected';

          console.log('[%s] Disconnected', this.scope.identifier);

          disconnect();
        }
      }
    })
  }
}

