import ApplicationController from '../application-controller';

import useVideo from '../../hooks/video/use-video';
import useTheaterMode from '../../hooks/video/use-theater-mode'
import useLogo from '../../hooks/video/use-logo';
import useWatermark from '../../hooks/video/use-watermark';
import useGoogleAnalytics from '../../hooks/video/use-google-analytics';
import useLocalAnalytics from '../../hooks/video/use-local-analytics';

export default class extends ApplicationController {
  static values = {
    eventsSrc: String,
    title: String,
    tracking: Boolean,
    embed: Boolean
  };

  initialize() {
    const [player, builder] = useVideo(this, this.element.firstChild);
    this.player = player;

    this.player.poster = () => "";

    if (!this.embedValue) {
      useTheaterMode(this, player, {
        theaterContainer: $('.player-theater-container'),
        defaultContainer: $('.video-player')
      })
    } else  {
      player.controlBar.removeChild('fullscreenToggle')
    }


    useLogo(player);
    useWatermark(this, player);

    if (this.trackingValue) {
      useGoogleAnalytics(this, player, this.titleValue);
    }

    useLocalAnalytics(this, player, this.eventsSrcValue)

    // const Button = builder.getComponent('Button');

    // TODO: Enable when ready next button by playlist
    // const nextButton = new Button(player, {
    //   clickHandler: (event) => {
    //     builder.log('Clicked');
    //   }
    // });
    // nextButton.addClass('vjs-next-control');
    // this.nexButton = this.player.controlBar.addChild(nextButton);

    // const broadcastButton = new Button(player, {
    //   clickHandler: (event) => {
    //     video.log('Clicked');
    //   }
    // });
    // broadcastButton.addClass('vjs-broadcast-control');
    // this.broadcastButton = player.controlBar.addChild(broadcastButton);
  }

  seek(time) {
    this.player.currentTime(time)
  }
}

