import ApplicationController from '../application-controller';
import slick from "slick-carousel";

export default class extends ApplicationController {
  connect() {
    $(this.element).slick({dots: true,
      infinite: false,
      // speed: 300,
      slidesToShow: 3,
      slidesToScroll: 3,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    })
  }

  disconnect() {
    $(this.element).slick('unslick');
  }
}

