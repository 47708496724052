import ApplicationController from '../application-controller';

export default class extends ApplicationController {
  static values = {
    selector: String
  }

  get videoController() {
    const videoElement = document.getElementById(this.selectorValue);
    return this.application.getControllerForElementAndIdentifier(videoElement, 'video')
  }

  seek(event) {
    event.stopPropagation();
    event.preventDefault();

    const { time } = event.params;
    console.log('seek %s', time);

    this.videoController.seek(time)
  }
}

