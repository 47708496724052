import ApplicationController from '../application-controller';

export default class extends ApplicationController {
  static targets = ['source']

  copy(event) {
    event.preventDefault();
    this.sourceTarget.select();
    document.execCommand('copy');
  }
}

